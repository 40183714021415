.RecordButton {
  position: relative;
}

.RecordButton .Button {
  position: relative;
  z-index: var(--z-index-high);
}

.RecordButton .RecordButton_circle {
  left: 0;
  position: absolute;
  top: 0;
  z-index: var(--z-index-low);
}

.RecordButton.--small .RecordButton_circle {
  transform: translate(-10px, -10px);
}

.RecordButton.--large .RecordButton_circle {
  transform: translate(-20px, -20px);
}
