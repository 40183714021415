.Greetings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 calc((var(--column)) + (var(--gutter)))
    calc((var(--column)) + (var(--gutter)));
}

.Greetings .greetings_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 calc((var(--column)) + (var(--gutter)))
    calc((var(--column)) + (var(--gutter)));
}

.Greetings .greetings__headline {
  color: var(--heliotrope);
  font-family: var(--LL-Circular);
  font-size: 28px;
  letter-spacing: 0.5px;
  margin: 60px auto 0;
  text-align: center;
}

.Greetings .setup__steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style: none;
  margin: 0 auto 120px;
  max-width: 912px;
  padding: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .Greetings .setup__steps {
    margin: 0 auto 60px;
  }
}

@media (min-width: 1024px) {
  .Greetings .setup__steps {
    align-items: flex-start;
    flex-wrap: nowrap;
  }
}

.Greetings .setup__step {
  font-family: var(--LL-Circular);
  text-align: center;
  width: 50%;
  padding: 0 12px;
}

@media (min-width: 768px) {
  .Greetings .setup__step {
    max-width: initial;
  }
}

@media (min-width: 1024px) {
  .Greetings .setup__step {
    width: auto;
  }
}

.Greetings .setup__step-one-animation,
.Greetings .setup__step-two-animation,
.Greetings .setup__step-three-animation,
.Greetings .setup__step-four-animation {
  margin: 0 auto;
  width: 50%;
}

@media (min-width: 600px) {
  .Greetings .setup__step-copy {
    padding-left: 18px;
    padding-right: 18px;
  }

  .Greetings .setup__step-one .setup__step-image {
    padding-top: 22px;
    width: 79px;
  }

  .Greetings .setup__step-one-animation {
    margin: 0 auto;
    width: 174px;
  }

  .Greetings .setup__step-two .setup__step-image {
    width: 175px;
  }

  .Greetings .setup__step-two .setup__step-heading {
    margin-top: 17px;
  }

  .Greetings .setup__step-two-animation {
    margin: 0 auto;
    width: 150px;
    height: 175px;
  }

  .Greetings .setup__step-three .setup__step-image {
    width: 175px;
  }

  .Greetings .setup__step-three-animation {
    margin: 0 auto;
    width: 175px;
  }

  .Greetings .setup__step-four .setup__step-image {
    width: 150px;
  }

  .Greetings .setup__step-four-animation {
    margin: 0 auto;
    width: 150px;
    height: 175px;
  }

  .Greetings .setup__step-four-animation path {
    fill: #584d6d;
  }

  .Greetings .setup__step-four .setup__step-heading {
    margin-top: 17px;
  }
}

.Greetings .setup__step-image {
  display: block;
  height: 116px;
  margin: 0 auto;
  width: 100%;
}

.Greetings .setup__step-number {
  color: var(--alto);
  display: block;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin-top: 40px;
}

.Greetings .setup__step-heading {
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  line-height: 30px;
  margin: 14px 0 0 0;
}

.Greetings .setup__step-copy {
  color: var(--spun-pearl);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 10px auto;
  max-width: 200px;
}

@media (min-width: 768px) {
  .Greetings .setup__step-copy {
    max-width: 262px;
  }
}

.Greetings .greeting__label {
  color: var(--spun-pearl);
  font-family: var(--LL-Circular);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 10px;
  text-align: center;
}

.Greetings .greeting__prompt {
  font-family: var(--LL-Circular);
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 1.25px;
  margin-bottom: 40px;
  margin-top: 0;
  text-align: center;
}

@media (min-width: 340px) {
  .Greetings .greeting__prompt {
    font-size: 30px;
    line-height: 34px;
  }
}

@media (max-height: 620px) {
  .Greetings .greeting__prompt {
    margin-bottom: 20px;
  }
}

@media (max-height: 670px) {
  .Greetings .greeting__prompt {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .Greetings .greeting__prompt {
    word-break: normal;
  }
}

.Greetings .greetings_status {
  color: var(--heliotrope);
  font-family: var(--LL-Circular);
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 1.37px;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
}

.help-text {
  color: var(--spun-pearl);
  display: block;
  font-family: var(--LL-Circular);
  font-size: 16px;
  font-weight: 500;
}

.help-text .help-link {
  color: var(--heliotrope);
  margin-left: 6px;
  text-decoration: none;
}

.Greetings .greetings__icon {
  display: block;
  color: var(--spun-pearl);
  margin: 0 auto -28px;
  width: 93px;
}

.Greetings .greetings__icon__mic {
  margin-bottom: 40px;
  width: 82px;
}

.Greetings .greetings__copy {
  max-width: 520px;
  margin: 24px auto;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 1024px) {
  .Greetings .greetings__copy {
    font-size: 20px;
  }
}

.Greetings .greetings__disclaimer {
  color: var(--spun-pearl);
  font-size: 16px;
  line-height: 1.5;
  max-width: 466px;
  margin: 24px auto;
  text-align: center;
}

.Greetings .greetings__disclaimer a {
  color: #fff;
  display: block;
  font-size: 12px;
  text-decoration: underline;
  text-decoration-color: var(--victoria);
  text-underline-position: under;
  padding-bottom: 4px;
  margin-left: 8px;
  margin-top: 20px;
  transition: color 0.15s ease, text-decoration-color 0.15s ease;
}

.Greetings .greetings__disclaimer a:hover,
.Greetings .greetings__disclaimer a:focus {
  color: var(--fog);
  text-decoration-color: var(--chatelle);
  outline: none;
}

.Greetings .greetings__disclaimer .external-link-icon {
  display: inline-block;
  max-width: 1em;
  margin-left: 10px;
  vertical-align: middle;
}

.Greetings .greetings__disclaimer .external-link-icon svg {
  max-width: 100%;
}

.greetings-footer__welcome .left-content {
  order: 1;
  width: 0;
}

.greetings-footer__welcome .center-content {
  order: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
}

.greetings-footer__welcome .right-content {
  order: 2;
  width: auto;
}

.greetings-footer .right-content {
  width: auto;
}

.Greetings .CustomRecordingInputIOS .RecordingInputIOS {
  height: auto;
}

.Greetings
  .CustomRecordingInputIOS
  .RecordingInputIOS
  .RecordingInputIOS_duration {
  background: transparent;
  font-size: 16px;
  margin: 0 auto;
  position: relative;
}

.Greetings .CustomRecordingInputIOS .RecordButton {
  margin-top: 0;
}

@media (max-height: 620px) {
  .Greetings .CustomRecordingInput .RecordButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 20px 0;
  }

  .Greetings .CustomRecordingInput .RecordButton .Button {
    position: static;
    transform: none;
  }
}
