.ContentFrame {
  -webkit-overflow-scrolling: touch;
  bottom: 0;
  left: 0;
  min-width: 320px;
  overflow: auto;
  padding: 30px var(--gutter) 83px;
  position: absolute;
  right: 0;
  top: 90px;
  z-index: var(--z-index-middle);
}

@media (min-width: 768px) {
  .ContentFrame {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 320px);
    overflow: unset;
    padding: 30px var(--gutter) 110px;
    position: relative;
    top: 120px;
  }
}

@media (min-width: 1024px) {
  .ContentFrame {
    padding: 30px 160px 110px;
  }
}

@media (min-height: 737px) {
  .ContentFrame__center {
    padding-top: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 90px;
  }
}
