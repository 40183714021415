.RecordingInput {
  width: 100%;
  height: 60px;
  border-radius: 10px;
  overflow: hidden;
  background: var(--bossanova);
  position: relative;
}

@media (min-width: 1024px) {
  .RecordingInput {
    width: 700px;
  }
}

.RecordingInput_buttons {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 120px;
  background: var(--victoria);
  border-right: 1px solid var(--port-gore);
  display: flex;
  align-items: center;
  justify-content: center;
}

.RecordingInput_buttons .Button + .Button {
  margin-left: 16px;
}

.RecordingInput_button_and_spinner {
  width: 38px;
}

.RecordingInput_button_and_spinner .Spinner {
  animation: unset;
  background: var(--carnation);
  margin: 0 14px 0 0;
}

.RecordingInput_button_and_spinner .Spinner .Spinner__image {
  display: none;
}

.RecordingInput_duration {
  font-family: var(--LL-Circular);
  font-size: 12px;
  line-height: 1;
  color: white;
  min-width: 48px;
  display: block;
}

@media (min-width: 768px) {
  .RecordingInput_duration {
    margin-left: 10px;
  }
}

.RecordingInput_visualizer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 121px;
  right: 0;
  transition: scale 1s ease-in-out;
}

.RecordingInput_middleLine {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  pointer-events: none;
  height: 1px;
  transform: translateY(-50%);
  background: var(--port-gore);
}

.CustomRecordingInput {
  width: 100%;
}

.CustomRecordingInput .RecordingInput_duration {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 64px;
  background: var(--victoria);
  border-right: 1px solid var(--port-gore);
  display: flex;
  align-items: center;
  justify-content: center;
}

.CustomRecordingInput .RecordButton {
  margin: 40px auto 0;
}

.CustomRecordingInput .RecordButton .Button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}
.CustomRecordingInput .RecordButton .RecordButton_circle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.CustomRecordingInput .RecordingInput_visualizer {
  left: 65px;
}

.CustomRecordingInput .RecordingInput_buttons {
  margin-top: 60px;
  background: none;
  top: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

@media (max-height: 620px) {
  .CustomRecordingInput .RecordingInput_buttons {
    position: static;
    transform: none;
    width: 100%;
    margin: 40px 0;
  }
}
