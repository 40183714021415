.Recorder {
  position: relative;
  transform: scaleX(-1);
}

.Recorder_visualizer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.Recorder_particles {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
