.chapter-row {
  align-items: center;
  color: white;
  border-bottom: solid 1px var(--smoky);
  display: grid;
  font-family: var(--LL-Circular);
  font-size: 16px;
  grid-gap: 10px;
  letter-spacing: 0.45px;
  padding: 20px 0 34px;
  position: relative;
  grid-template-columns: 40px 10fr 1fr;
}

.chapter-row.--editing {
  border-top: solid 1px var(--smoky);
  margin-bottom: -1px;
  padding: 24px 0 24px;
}

.chapter-row.--editing .chapter-order {
  color: var(--heliotrope);
}

.chapter-row .chapter-duration {
  color: var(--heliotrope);
  margin-right: 20px;
  min-width: 44px;
  text-align: left;
}

@media (min-width: 768px) {
  .chapter-row .chapter-duration {
    text-align: center;
  }
}

.chapter-row .chapter-title-wrapper {
  display: flex;
  flex-flow: column;
  min-height: 20px;
  min-width: 80px;
  position: relative;
  padding-left: 15px;
}

.chapter-row .TextInput {
  padding: 8px 16px;
  margin: 10px 0;
}

@media (min-width: 768px) {
  .chapter-row .chapter-title-wrapper {
    align-items: center;
    flex-flow: row;
  }
}

.chapter-edit {
  margin-left: 10px;
}

.chapter-title {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 900;
  margin: 3px 0;
  position: relative;
}

@media (min-width: 768px) {
  .chapter-title {
    margin: 0;
  }
}

.chapter-owner-wrapper {
  bottom: 0;
  display: flex;
  font-weight: 600;
  letter-spacing: 0.6px;
  opacity: 0.5;
  padding-top: 7px;
  position: absolute;
  transform: translateY(100%);
}

.chapter-owner-wrapper .mic-icon {
  display: block;
  height: 15px;
  margin-right: 3px;
  width: 15px;
}

.chapter-owner {
  display: block;
  font-size: 12px;
}

.chapter-title + .chapter-owner-wrapper {
  bottom: 0;
  transform: translateY(calc(100% + 6px));
}

.chapter-resume {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin-right: 0;
}
.chapter-resume .Button.--oval.--icon-left {
  padding: 10px;
  width: 44px;
}

.chapter-resume .Button.--oval.--icon-left .Button_icon {
  height: 20px;
  margin-right: 0;
  width: 20px;
}

.chapter-resume .Button.--oval.--icon-left .Button_label {
  display: none;
}

/* Tablet+ */
@media (min-width: 768px) {
  .chapter-resume .Button.--oval.--icon-left {
    padding: 10px 20px;
    width: auto;
  }

  .chapter-resume .Button.--oval.--icon-left .Button_icon {
    margin-right: 8px;
  }

  .chapter-resume .Button.--oval.--icon-left .Button_label {
    display: block;
  }
}

.chapter-row .chapter-order {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.chapter-row .chapter-order svg {
  max-width: 40px;
}
