.Header .header-wrapper {
  background-color: var(--port-gore);
  position: absolute;
  top: 0;
  left: 50%;
  min-width: 320px;
  overflow: hidden;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
  margin: 0 auto;
  z-index: var(--z-index-high);
}

.Header .header-bg {
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 100%;
}

.header-bg svg {
  min-width: 960px;
  left: 50%;
  position: relative;
  transform: translateX(-50%) !important;
}

@media (min-width: 768px) {
  .header-bg svg {
    min-width: 1920px;
  }
}

.Header .header-title {
  height: 90px;
  margin: 0;
  position: relative;
  width: 100%;
}

@media (min-width: 768px) {
  .Header .header-title {
    height: 128px;
  }
}

.Header .non-interactive-header-logo,
.Header .header-logo {
  display: block;
  height: 60px;
  width: 157px;
  left: 50%;
  top: 24px;
  transform: translateX(-50%);
  transition: filter 0.15s ease-in-out, transform 0.15s ease-in-out;
  position: absolute;
}

@media (min-width: 768px) {
  .Header .non-interactive-header-logo,
  .Header .header-logo {
    height: 70px;
    width: 183px;
  }
}

.Header .header-logo:hover {
  transform: translateX(-50%) scale(1.1);
}

.Header .header-logo:focus {
  transform: translateX(-50%) scale(1.05);
  outline: 0;
}

.Header .settings-button {
  opacity: 0;
  position: fixed;
  right: var(--page-margin);
  top: var(--page-margin);
  transition: var(--opacity-transition);
  z-index: var(--z-index-high);
}

.Header .settings-button.-active {
  opacity: 1;
}
