.Error {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 60vh;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  padding: 0 24px;
}

.Error .message {
  font-family: var(--LL-Circular);
  font-size: 24px;
}

.Error.--invalid-invite .header {
  font-size: 28px;
  color: var(--heliotrope);
  margin: 40px auto 20px;
}

.Error.--invalid-invite .message {
  font-size: 18px;
  margin: 0;
  max-width: 700px;
}
