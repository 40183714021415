.StoryDetail {
  width: calc(100vw - (var(--column)) - calc(2 * (var(--gutter))));
  margin: 0 auto 60px;
  padding-bottom: 110px;
}

@media (min-width: 768px) {
  .StoryDetail {
    padding: 0 calc((var(--column)) + (var(--gutter)));
  }
}
@media (min-width: 1024px) {
  .StoryDetail {
    width: 100%;
    padding: 0;
    max-width: var(--large-content-width);
  }
}

.StoryDetail_title {
  display: inline-block;
  font-size: 28px;
  letter-spacing: 0.26px;
  line-height: 1;
  margin: 0 8px 8px 0;
}

.StoryDetail_heading {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: var(--page-margin);
  padding: 0 calc((var(--column) / 2) + var(--gutter));
}

@media (min-width: 768px) {
  .StoryDetail_heading {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .StoryDetail_heading .Button.--oval-tertiary {
    height: 30px;
    padding: 0 12px;
    position: relative;
  }

  .StoryDetail_heading .Button.--oval-tertiary::before {
    content: "";
    display: block;
    height: 44px;
    left: 0;
    position: absolute;
    top: -10px;
    width: 100%;
  }

  .StoryDetail_heading .Button.--oval-tertiary .Button_label {
    margin-top: -1px;
  }
}

.StoryDetail_actions {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: var(--page-margin);
  flex-wrap: wrap;
  padding: 0 calc((var(--column) / 2) + var(--gutter));
}

@media (min-width: 768px) {
  .StoryDetail_actions {
    padding: 0;
  }
}

.StoryDetail_owner {
  display: block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.6px;
  margin-top: 8px;
  opacity: 0.5;
  width: 100%;
}

.StoryDetail_actions .Button {
  margin: 0 8px 8px 0;
}

.story-completed {
  display: flex;
  flex-grow: 1;
  margin-right: 0;
  opacity: 0.7;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 1px;
  align-items: center;
}

.story-completed svg {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  margin-top: 4px;
}

.switch-wrapper {
  margin-top: var(--page-margin);
}

.switch {
  display: none;
}
.switch,
.switch:after,
.switch:before,
.switch *,
.switch *:after,
.switch *:before,
.switch + .switch-button {
  box-sizing: border-box;
}
.switch::selection,
.switch:after::selection,
.switch:before::selection,
.switch *::selection,
.switch *:after::selection,
.switch *:before::selection,
.switch + .switch-button::selection {
  background: none;
}
.switch + .switch-button {
  outline: 0;
  display: inline-block;
  width: 64px;
  height: 36px;
  position: relative;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
}
.switch + .switch-button:after,
.switch + .switch-button:before {
  position: relative;
  display: block;
  content: "";
  width: 30px;
  height: 30px;
}
.switch + .switch-button:after {
  left: 0;
}
.switch + .switch-button:before {
  display: none;
}
.switch:checked + .switch-button:after {
  background-image: url("/assets/checkmark.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-color: var(--bermuda);
  left: 50%;
}
.switch + .switch-button {
  border: solid 3px var(--spun-pearl-light);
  border-radius: 20px;
  padding: 0;
  transition: border 0.4s ease, background 0.4s ease;
}
.switch + .switch-button:after {
  border-radius: 30px;
  background: var(--spun-pearl);
  transition: left 0.2s ease, background-color 0.2s ease;
}
.switch:checked + .switch-button {
  border: solid 3px transparent;
  background: var(--bermuda-light);
}

.switch-label {
  display: inline-block;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 1.03px;
  margin-left: 12px;
  text-transform: uppercase;
  vertical-align: middle;
}

.StoryDetail_footer .left-content {
  width: auto;
}

.StoryDetail_footer .center-content {
  width: 0;
}

.StoryDetail_footer .right-content {
  width: auto;
}

@media (min-width: 370px) {
  .StoryDetail_footer .right-content {
    margin-bottom: 0;
  }
}
