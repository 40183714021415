.ChapterTitleInfo {
  box-sizing: border-box;
  margin: 0 auto;
  min-width: 280px;
  min-height: 100%;
  padding: 0 0 56px;
  max-width: 700px;
  width: 100%;
}

.ChapterTitleInfo .RecordingInputIOS {
  align-items: baseline;
}

.ChapterTitleInfo .section__headline {
  color: var(--heliotrope);
  font-family: var(--LL-Circular);
  font-size: 28px;
  letter-spacing: 0.7px;
  margin: 0 auto 52px;
  text-align: center;
}

.ChapterTitleInfo .create_chapter_name_input_wrapper {
  display: flex;
  justify-content: space-between;
}

.ChapterTitleInfo .create_chapter_name_input_wrapper input::placeholder {
  color: var(--fog);
}

.ChapterTitleInfo .create_chapter_label {
  background-color: var(--victoria);
  border-radius: 14px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.44px;
  line-height: 1.5;
  margin: 0 16px 0 0;
  padding: 18px var(--page-margin);
  white-space: nowrap;
}
