.GoogleHomeTip {
  text-align: center;
  max-width: 700px;
  margin: 55px auto 0;
}

.GoogleHomeTip .tip-text {
  font-size: 16px;
  color: var(--spun-pearl);
}

.GoogleHomeTip .emphasized-text {
  display: block;
  font-size: 30px;
  color: var(--aqua-spring);
  margin: 10px auto 22px;
}
