.PlayerButton {
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
}

.PlayerButton_canvas {
  position: absolute;
  top: -4px;
  left: -4px;
  pointer-events: none;
}

.PlayerButton .Button.--circle {
  background: transparent;
  box-shadow: var(--box-shadow-outline-small-dark);
  color: white;
}

.PlayerButton .Button.--circle:focus {
  box-shadow: 0 0 0 3px var(--heliotrope);
}
