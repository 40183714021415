.TextInput {
  background-color: var(--victoria);
  border: 0;
  border-radius: 14px;
  color: var(--fog);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.44px;
  line-height: 1.5;
  outline: 0;
  padding: 18px var(--page-margin);
  transition: var(--outline-transition);
  width: 100%;
}

.TextInput:focus {
  box-shadow: var(--box-shadow-outline);
}
