.RecordingInputIOS {
  width: auto;
  height: 60px;
  overflow: hidden;
  background: transparent;
  position: relative;
}

/* Tablet+ */
@media (min-width: 768px) {
  .RecordingInputIOS {
    width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }
}

.RecordingInputIOS_buttons {
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 120px;
  background: var(--victoria);
  border-right: 1px solid var(--port-gore);
  display: flex;
  align-items: center;
  justify-content: center;
}

.RecordingInputIOS_buttons .Spinner {
  margin: 0 8px 0 0;
}

.RecordingInputIOS__paused {
  width: 180px;
  padding-left: 12px;
  justify-content: flex-start;
}

.RecordingInputIOS__paused .RecordingInputIOS_duration {
  text-align: right;
}

/* Tablet+ */
@media (min-width: 768px) {
  .RecordingInputIOS_buttons {
    position: static;
    height: 60px;
  }
  .RecordingInputIOS__paused {
    padding-left: 20px;
  }
}

.RecordingInputIOS_buttons .Button + .Button {
  margin-left: 16px;
}

.RecordingInputIOS_buttons .RecordButton {
  margin-left: 0;
}

.RecordingInputIOS_duration {
  font-family: var(--LL-Circular);
  font-size: 12px;
  line-height: 1;
  color: white;
  min-width: 48px;
  display: block;
}

/* Tablet+ */
@media (min-width: 768px) {
  .RecordingInputIOS_duration {
    font-size: 16px;
    text-align: center;
  }
}

.RecordingInputIOS_buttons .RecordButton + .RecordingInputIOS_duration {
  margin-left: 8px;
}

.RecordingInputIOS_visualizer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 121px;
  right: 0;
  transition: scale 1s ease-in-out;
}

.CustomRecordingInputIOS {
  width: 100%;
}

.CustomRecordingInputIOS .RecordingInputIOS_duration {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 64px;
  background: var(--victoria);
  border-right: 1px solid var(--port-gore);
  display: flex;
  align-items: center;
  justify-content: center;
}

.CustomRecordingInputIOS .RecordButton {
  margin: 40px auto 0;
}

.CustomRecordingInputIOS .RecordButton .Button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}
.CustomRecordingInputIOS .RecordButton .RecordButton_circle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.CustomRecordingInputIOS .RecordingInputIOS_visualizer {
  left: 65px;
}

.CustomRecordingInputIOS .RecordingInputIOS_buttons {
  margin-top: 60px;
  background: none;
  top: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

@media (max-height: 620px) {
  .CustomRecordingInputIOS .RecordingInputIOS_buttons {
    position: static;
    transform: none;
    width: 100%;
    margin: 40px 0;
  }
}
