.NewStoryTile {
  position: relative;
  width: 295px;
  height: 295px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.46); /* iphone SE */
}

@media (min-width: 375px) {
  .NewStoryTile {
    transform: translate(-50%, -50%) scale(0.53); /* iphone 6/7/8 */
  }
}

@media (min-width: 414px) {
  .NewStoryTile {
    transform: translate(-50%, -50%) scale(0.57); /* iphone 6/7/8 plus */
  }
}

@media (min-width: 414px) {
  .NewStoryTile {
    transform: translate(-50%, -50%) scale(0.57); /* iphone 6/7/8 plus */
  }
}

@media (min-width: 480px) {
  .NewStoryTile {
    transform: translate(-50%, -50%) scale(0.76); /* all other medium phones */
  }
}

@media (min-width: 600px) {
  .NewStoryTile {
    transform: translate(-50%, -50%) scale(1); /* all other large phones */
  }
}

@media (min-width: 1024px) {
  .NewStoryTile {
    transform: translate(-50%, -50%) scale(0.76); /* desktop */
  }
}

.NewStoryTile .Button.--filled {
  background-color: var(--aqua-spring);
  border: 4px solid var(--aqua-spring);
  border-radius: 54px;
  font-size: 21px;
  font-weight: 900;
  letter-spacing: 1.8px;
  left: 50%;
  padding: 24px 32px;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateX(calc(-50% - 5px)) translateY(calc(-50% - 5px));
  width: 256px;
  display: flex;
  justify-content: space-evenly;
}

.NewStoryTile .Button.--icon-left .Button_icon {
  height: 25px;
  width: 25px;
  margin-right: 16px;
}

@media (min-width: 768px) {
  .NewStoryTile .Button.--filled {
    left: 0;
    padding: 26px 34px 26px 29px;
    top: 96px;
    transform: none;
    width: auto;
    max-width: 266px;
  }
  .NewStoryTile .Button.--icon-left .Button_icon {
    margin-right: 26px;
  }
}
