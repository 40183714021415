.chapter-list-title {
  color: white;
  font-family: var(--LL-Circular);
  font-size: 28px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.26px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 28px;
}

.chapter-list-list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.add-chapter-row {
  background: rgba(255, 255, 255, 0.26);
  display: block;
  padding: 20px 0 24px;
  position: relative;
  text-align: center;
}

.add-chapter-row:hover {
  background: rgba(255, 255, 255, 0.26);
}

@media (min-width: 768px) {
  .add-chapter-row {
    background: transparent;
  }
}

.add-chapter-button {
  color: #fff;
  height: 100%;
}

.add-chapter-button svg {
  display: inline-block;
  height: 14px;
  margin-right: 8px;
  vertical-align: middle;
  width: 14px;
}

.add-chapter-label {
  display: inline-block;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 1.03px;
  text-transform: uppercase;
  vertical-align: middle;
}
