.DeviceGate .ContentFrame {
  align-items: center;
  text-align: center;
  top: 0;
}

.DeviceGate .content {
  max-width: 79.1666667%;
  margin: 0 auto;
}

.DeviceGate .title {
  color: var(--heliotrope);
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 20px;
}

@media (min-width: 600px) {
  .DeviceGate .title {
    font-size: 30px;
    line-height: 38px;
  }
}

.DeviceGate .body {
  margin-bottom: 40px;
}

.DeviceGate .cta {
  margin-bottom: 20px;
}

.DeviceGate .link {
  color: var(--fog);
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.45px;
}

.DeviceGate .illustration {
  margin: 60px auto 40px;
}
