.invite {
  text-align: center;
  padding: 0 calc((var(--column)) + (var(--gutter)))
    calc((var(--column)) + (var(--gutter)));
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 768px) {
  .invite {
    padding: 0 calc(2 * calc((var(--column)) + (var(--gutter))));
  }
}

@media (min-width: 1024px) {
  .invite {
    padding: 0;
  }
}

.invite__headline {
  color: var(--heliotrope);
  font-family: var(--LL-Circular);
  font-size: 28px;
  letter-spacing: 0.7px;
  margin: 0 auto 52px;
  text-align: center;
}

.invite p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0 auto 40px;
  max-width: 560px;
}

.invite__input {
  display: block;
  width: 100%;
  margin: 0 auto 40px;
}

@media (min-width: 1024px) {
  .invite__input {
    max-width: 560px;
  }
}

.invite__input ::placeholder {
  color: var(--fog);
}

.select-story-list {
  list-style-type: none;
  padding: 0 calc((var(--column)) + (var(--gutter)))
    calc((var(--column)) + (var(--gutter)));
}

@media (min-width: 768px) {
  .select-story-list {
    padding: 0 calc(2 * (calc((var(--column)) + (var(--gutter)))))
      calc(2 * (calc((var(--column)) + (var(--gutter)))));
  }
}

@media (min-width: 1024px) {
  .select-story-list {
    padding: 0 0 110px;
    max-width: var(--large-content-width);
    width: 100%;
    margin: 0 auto;
  }
}

.select-story-item {
  border: solid 3px transparent;
  border-radius: 14px;
  color: var(--port-gore);
  width: 100%;
  padding: 30px;
  margin-bottom: 16px;
  cursor: pointer;
  position: relative;
}

.select-story-item.--carnation {
  background: var(--carnation);
  box-shadow: 0 0 0 0 var(--carnation), var(--blank-base-shadow);
}

.select-story-item.--carnation:hover {
  box-shadow: 0 0 0 8px var(--carnation), var(--base-shadow);
}

.select-story-item.--carnation:focus {
  box-shadow: 0 0 0 4px var(--carnation), var(--base-shadow);
  outline: none;
}

.select-story-item.--bermuda {
  background: var(--bermuda);
  box-shadow: 0 0 0 0 var(--bermuda);
}

.select-story-item.--bermuda:hover {
  box-shadow: 0 0 0 8px var(--bermuda), var(--base-shadow);
}

.select-story-item.--bermuda:focus {
  box-shadow: 0 0 0 4px var(--bermuda), var(--base-shadow);
  outline: none;
}

.select-story-item.--your_pink {
  background: var(--your_pink);
  box-shadow: 0 0 0 0 var(--your_pink);
}

.select-story-item.--your_pink:hover {
  box-shadow: 0 0 0 8px var(--your_pink), var(--base-shadow);
}

.select-story-item.--your_pink:focus {
  box-shadow: 0 0 0 4px var(--your_pink), var(--base-shadow);
  outline: none;
}

.select-story-item.--tumbleweed {
  background: var(--tumbleweed);
  box-shadow: 0 0 0 0 var(--tumbleweed);
}

.select-story-item.--tumbleweed:hover {
  box-shadow: 0 0 0 8px var(--tumbleweed), var(--base-shadow);
}

.select-story-item.--tumbleweed:focus {
  box-shadow: 0 0 0 4px var(--tumbleweed), var(--base-shadow);
  outline: none;
}

.select-story-item.--heliotrope {
  background: var(--heliotrope);
  box-shadow: 0 0 0 0 var(--heliotrope);
}

.select-story-item.--heliotrope:hover {
  box-shadow: 0 0 0 8px var(--heliotrope), var(--base-shadow);
}

.select-story-item.--heliotrope:focus {
  box-shadow: 0 0 0 4px var(--heliotrope), var(--base-shadow);
  outline: none;
}

.select-story-item.--vivid_tangerine {
  background: var(--vivid_tangerine);
  box-shadow: 0 0 0 0 var(--vivid_tangerine);
}

.select-story-item.--vivid_tangerine:hover {
  box-shadow: 0 0 0 8px var(--vivid_tangerine), var(--base-shadow);
}

.select-story-item.--vivid_tangerine:focus {
  box-shadow: 0 0 0 4px var(--vivid_tangerine), var(--base-shadow);
  outline: none;
}

.select-story-item.--ziggurat {
  background: var(--ziggurat);
  box-shadow: 0 0 0 0 var(--ziggurat);
}

.select-story-item.--ziggurat:hover {
  box-shadow: 0 0 0 8px var(--ziggurat), var(--base-shadow);
}

.select-story-item.--ziggurat:focus {
  box-shadow: 0 0 0 4px var(--ziggurat), var(--base-shadow);
  outline: none;
}

.select-story-item.--sandwisp {
  background: var(--sandwisp);
  box-shadow: 0 0 0 0 var(--sandwisp);
}

.select-story-item.--sandwisp:hover {
  box-shadow: 0 0 0 8px var(--sandwisp), var(--base-shadow);
}

.select-story-item.--sandwisp:focus {
  box-shadow: 0 0 0 4px var(--sandwisp), var(--base-shadow);
  outline: none;
}

@media (max-width: 767px) {
  .invitation .Footer.unfixed-true {
    bottom: -83px;
    margin: unset;
    position: absolute;
    width: 100%;
  }
}

.select-story-item .story-item-title {
  font-size: 20px;
  line-height: 38px;
  font-weight: 700;
}

.select-story-item .story-item-share-label {
  align-items: center;
  display: flex;
  opacity: 0.5;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  width: 85%;
}

.select-story-item .story-item-share-label svg {
  margin-right: 4px;
}

.select-story-item .story-item-share-label ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0 0 0 4px;
}

.select-story-item .story-item-share-label li {
  margin-right: 4px;
}

.select-story-item .story-item-share-label li:last-child {
  margin-right: 0;
}

.select-story-item .story-item-checkbox {
  border: solid 3px;
  border-radius: 12px;
  color: var(--port-gore);
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  top: 50%;
  right: 35px;
  transform: translateX(-50%) translateY(-50%);
}

.select-story-item.--selected {
  border: solid 3px #fff;
  color: #fff;
}

.select-story-item.--selected .story-item-checkbox {
  color: #fff;
  background: url("/assets/checkmark.svg") no-repeat 50% 50%;
  background-size: 14px 12px;
}

.ShareError {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  padding: 0 24px;
}

.ShareError.--no-stories .header {
  font-size: 28px;
  color: var(--heliotrope);
  margin: 40px auto 20px;
}

.ShareError.--no-stories .message {
  font-size: 18px;
  margin: 0;
  max-width: 700px;
}
