.Footer {
  align-items: center;
  background-color: var(--port-gore);
  border-top: solid 3px var(--victoria);
  bottom: 0;
  display: flex;
  height: auto;
  justify-content: space-between;
  left: 0;
  min-width: 320px;
  padding: 18px 12px;
  position: fixed;
  width: 100%;
  z-index: var(--z-index-high);
}

@media (max-width: 767px) {
  .Footer.unfixed-true {
    margin: 48px 0 0 -24px;
    position: relative;
    width: calc(100% + 48px);
  }
}

@media (max-width: 359px) {
  .Footer.unfixed-true {
    min-width: unset;
  }
}

@media (min-width: 768px) {
  .Footer {
    height: calc(80px + var(--page-margin));
    padding: var(--page-margin);
  }
}

.Footer.--scrim {
  background: var(--port-gore);
}

.Footer .left-content,
.Footer .center-content,
.Footer .right-content {
  flex-shrink: 0;
  width: 33.33%;
  align-items: center;
  display: flex;
}

.Footer .center-content {
  width: auto;
}

.Footer .right-content {
  display: inline-flex;
  justify-content: flex-end;
}

.Footer .right-content .Button_file-wrapper {
  text-align: right;
}

.TransitionWrapper-enter .Footer,
.TransitionWrapper-enter-active .Footer {
  transform: translateY(16px);
}

.TransitionWrapper-enter-done .Footer {
  transition: var(--transform-transition);
  transform: translateY(0);
}

.TransitionWrapper-exit .Footer {
  transition: var(--transform-transition);
  transform: translateY(0);
}

.TransitionWrapper-exit-active .Footer {
  transform: translateY(16px);
}
