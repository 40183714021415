.AccountSettings {
  padding: 0 calc((var(--column)) + (var(--gutter)))
    calc((var(--column)) + (var(--gutter)));
}

@media (min-width: 768px) {
  .AccountSettings {
    padding: 0 calc(2 * (calc((var(--column)) + (var(--gutter)))))
      calc(2 * (calc((var(--column)) + (var(--gutter)))));
  }
}

@media (min-width: 1024px) {
  .AccountSettings {
    padding: 0 0 110px;
    max-width: var(--large-content-width);
    width: 100%;
    margin: 0 auto;
  }
}

.AccountSettings .account__headline {
  color: var(--heliotrope);
  font-family: var(--LL-Circular);
  font-size: 28px;
  letter-spacing: 0.5px;
  margin: 52px auto 0;
  text-align: center;
  line-height: 38px;
}

.AccountSettings .account__container {
  margin: 0 auto;
}

.AccountSettings .account__email {
  font-family: var(--LL-Circular);
  font-weight: 500;
  font-size: 16px;
  color: var(--spun-pearl);
  line-height: 24px;
  text-align: center;
  margin: 10px 0 0;
}

.AccountSettings .account__links,
.AccountSettings .account__share-links {
  list-style: none;
  padding: 0;
}

.AccountSettings .account__links {
  margin-top: 40px;
}

.AccountSettings .account__share-links {
  margin-top: 30px;
}

.AccountSettings .account__share-links .buttonContainer {
  margin-top: 16px;
}

@media (min-width: 768px) {
  .AccountSettings .account__share-links .buttonContainer {
    margin-top: 0;
  }
}

.AccountSettings .account__link {
  margin-bottom: 24px;
}

.AccountSettings .account__link .button-minimal {
  text-align: left;
}

.AccountSettings .account-hr {
  display: block;
  margin: 48px auto 64px;
  height: 1px;
  border: none;
  border-radius: 2px;
  background-color: var(--mulled-wine);
}

@media (min-width: 650px) {
  .AccountSettings .account__share-link {
    display: grid;
    grid-template-columns: 60% 20% 20%;
    width: 100%;
  }
}

.AccountSettings .account__username {
  font-family: var(--LL-Circular);
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  letter-spacing: 1px;
  line-height: 18px;
}

.AccountSettings .account__share-status {
  align-items: center;
  display: flex;
}

.AccountSettings .share__status {
  font-family: var(--LL-Circular);
  font-weight: 500;
  font-size: 13px;
  color: var(--bermuda);
  line-height: 24px;
  margin-left: 8px;
}

@media (min-width: 650px) {
  .AccountSettings .buttonContainer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
}

.AccountSettings .shared_stories_headline {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.45px;
  color: var(--heliotrope);
  font-weight: 700;
}

.AccountSettings .shared_stories_subheadline {
  font-size: 13px;
  line-height: 24px;
  color: var(--chatelle);
  font-weight: 600;
  display: block;
  margin-bottom: 30px;
}

.AccountSettings .tab-list {
  margin-bottom: 30px;
}

.AccountSettings .tab-list button[role="tab"] {
  min-width: auto;
  width: 50%;
}

.AccountSettings .tab-panel .account-hr {
  margin: 30px auto;
}

.AccountSettings .tab-panel .share-section-header {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.45px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.AccountSettings .tab-panel .share-section-list {
  padding: 0;
  list-style-type: none;
}

.AccountSettings .tab-panel .share-section-list .--circle-delete-editor {
  display: none;
}

.AccountSettings
  .tab-panel
  .share-section-list.--editing
  .--circle-delete-editor {
  display: inherit;
}

.AccountSettings .tab-panel .share-section-item {
  display: flex;
  justify-content: space-between;
}

.AccountSettings .tab-panel .share-section-label,
.AccountSettings .tab-panel .share-section-status {
  display: block;
  font-size: 13px;
  line-height: 24px;
  font-weight: 600;
}

.AccountSettings .tab-panel .share-section-label {
  color: var(--chatelle);
  display: flex;
  align-items: center;
}

.AccountSettings .tab-panel .share-section-status {
  color: var(--bermuda);
}

.AccountSettings .tab-panel .share-section-status svg {
  margin-right: 8px;
}

.AccountSettings .tab-panel.--inactive {
  display: none;
}

.AccountSettings .button-minimal.--small {
  float: right;
}

.AccountSettings .--circle-delete-editor {
  margin-right: 8px;
  transition: none;
}
.AccountSettings .--circle-delete-editor .Button_icon {
  align-items: center;
  display: flex;
  height: 14px;
  justify-content: center;
  width: 14px;
}

.AccountSettings .share-section-label .--circle-delete-editor {
  margin-left: 24px;
}

.AccountSettings .--circle-delete-editor:hover {
  box-shadow: none;
  color: #fff;
  border-color: #fff;
}
.AccountSettings
  .tab-panel
  .share-section-list.--editing
  .--invite-item
  .share-section-label {
  margin-left: 50px;
}
