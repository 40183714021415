.EditChapterTitle {
  padding: 0 calc((var(--column)) + (var(--gutter)));
}

.EditChapterTitle .RecordingInput,
.EditChapterTitle .RecordingInputIOS {
  margin-top: 48px;
}

.EditChapterTitle .RecordingInput_duration {
  margin-left: 10px;
}
