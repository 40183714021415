.RecordChapter {
  position: absolute;
  top: 48%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.RecordChapter_midline {
  background: var(--bossanova);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  height: 3px;
}

@media (min-width: 768px) {
  .RecordChapter {
    position: fixed;
    top: 50%;
  }
}

.RecordChapter_headline-container {
  font-family: var(--LL-Circular);
  text-align: center;
  position: absolute;
  width: 100%;
  transform: translateX(-50%);
  left: 50%;
  top: 58px;
}

.RecordChapter_headline {
  font-size: 34px;
  color: white;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (max-height: 620px) {
  .RecordChapter_headline {
    -webkit-line-clamp: 1;
  }
}

.RecordChapter_subheadline {
  font-size: 16px;
  color: var(--heliotrope);
  margin: 0 0 0.25em 0;
}

.RecordChapter_controls {
  position: absolute;
  width: 50%;
  transform: translateX(50%);
  left: 0;
  bottom: 125px;
}

.RecordChapter_controls.safari_controls {
  bottom: 38%;
}

.RecordChapter_duration {
  font-family: var(--LL-Circular);
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: -68px;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  width: 100%;
}

@media (min-width: 768px) {
  .RecordChapter_duration {
    top: -80px;
  }
}

.RecordChapter_unrecorded-duration {
  color: var(--carnation);
}

.RecordChapter_buttons {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: calc(-1 * var(--page-margin));
}

.RecordChapter_buttons_and_spinner {
  height: 80px;
  width: 80px;
}

.RecordChapter_buttons .Button {
  display: inline-block;
  flex-shrink: 0;
}

.RecordChapter_buttons .Button.--circle-delete {
  margin-right: 30px;
}

.RecordChapter_buttons .Button.--circle-play {
  margin-left: 30px;
}

.RecordChapter_footer .left-content {
  width: auto;
}

.RecordChapter_footer .center-content {
  width: 0;
}

.RecordChapter_footer .right-content {
  width: auto;
}

@media (max-width: 370px) {
  .RecordChapter_footer .left-content,
  .RecordChapter_footer .right-content {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .RecordChapter_controls {
    bottom: -130px;
  }

  .RecordChapter_controls.safari_controls {
    bottom: 20%;
  }

  .RecordChapter_footer {
    border-top: 0;
    z-index: var(--z-index-low);
  }
}

@media (max-height: 620px) {
  .RecordChapter {
    top: 40%;
  }

  .RecordChapter_headline-container {
    top: 0;
  }

  .RecordChapter_visualizer {
    margin: 0 0 48px;
  }
}

@media (min-width: 768px) and (max-height: 620px) {
  .RecordChapter_controls.safari_controls {
    bottom: -100px;
  }
}
