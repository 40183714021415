.CreateStory {
  padding: 0 calc((var(--column)) + (var(--gutter)));
}

@media (min-width: 768px) {
  .CreateStory {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 704px;
  }
}

@media (min-width: 1024px) {
  .CreateStory {
    padding: 0;
  }
}

.CreateStory .section__headline {
  color: var(--heliotrope);
  font-family: var(--LL-Circular);
  font-size: 28px;
  letter-spacing: 0.7px;
  margin: 0 auto 52px;
  text-align: center;
}

.CreateStory .create_text_inputs_wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.CreateStory .create_text_input {
  width: 100%;
}

.CreateStory .create_recording_row {
  margin-bottom: 40px;
}

.CreateStory .create_toggle_chapters {
  margin-bottom: 40px;
}

.CreateStory #setup_title {
  margin-top: 0;
}

.CreateStory #setup_author {
  margin-top: 40px;
  white-space: nowrap;
}

.CreateStory .RadioGroup label:first-of-type {
  padding-left: 0;
}

.CreateStory .RecordingInput_duration {
  margin-left: 10px;
}

@media (min-width: 768px) {
  .CreateStory #setup_author {
    margin-top: unset;
  }
  .CreateStory .create_text_inputs_wrapper {
    flex-wrap: nowrap;
  }
  .CreateStory .create_text_input.--first {
    margin-right: 24px;
  }
}

.CreateStory_footer .left-content {
  width: auto;
}

.CreateStory_footer .center-content {
  width: 0;
}

.CreateStory_footer .right-content {
  width: auto;
}

/* Tablet+ */
@media (min-width: 768px) {
  .CreateStory .RecordingInputIOS {
    width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    margin-top: 1.33em;
  }

  .CreateStory .RecordingInputIOS__paused {
    justify-content: flex-start;
  }

  .CreateStory .RecordingInputIOS_duration {
    width: 50px;
    flex: none;
  }
}
