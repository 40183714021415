body {
  /* Primary Colors */
  --port-gore: #291b44;
  --heliotrope: #bd72f2;
  --carnation: #f8656a;
  --victoria: #5c497f;
  --bossanova: #402e60;
  --aqua-spring: #f4fafc;
  --aluminium: #80868b;

  /* Tile Colors */
  --your_pink: #ffc2bd;
  --tumbleweed: #dcab7c;
  --bermuda: #85e0c0;
  --bermuda-light: rgba(133, 224, 192, 0.3);
  --vivid_tangerine: #ff9e7c;
  --ziggurat: #badae4;
  --sandwisp: #f7eaa2;

  /* Text Colors */
  --spun-pearl: #aba6b9;
  --spun-pearl-light: rgba(171, 166, 185, 0.3);
  --fog: #e4d5ff;
  --medium-purple: #986ffc;
  --rum: #73668e;
  --chatelle: #bfb8cd;
  --deluge: #745d9d;
  --alto: #d8d8d8;
  --mulled-wine: #584d6d;

  /* Divider Colors */
  --affair: #6c5080;
  --smoky: #5e5772;

  /* Layout vars */
  --page-margin: 30px;
  --page-margin-small: 24px;
  --large-content-width: 700px;
  --med-content-width: 460px;
  --column: calc(
    100vw / 12 * 0.75
  ); /* 100% / 12 columns / 4 (3:1 ratio to gutter) */
  --gutter: calc(100vw / 12 * 0.25);

  /* transitions */
  --outline-transition: box-shadow 0.15s ease-in-out;
  --color-transition: color 0.15s ease;
  --background-color-transition: background-color 0.15s ease;
  --border-color-transition: border-color 0.15s ease;
  --opacity-transition: opacity 0.15s ease-in-out;
  --slow-opacity-transition: opacity 0.25s ease-in-out;
  --transform-transition: transform 0.15s ease-in-out;

  /* borders */
  --box-shadow-outline: 0 0 0 4px #fff;
  --box-shadow-outline-small: 0 0 0 3px #fff;
  --box-shadow-outline-small-dark: 0 0 0 3px var(--deluge);

  /* z-index */
  --z-index-low: 1;
  --z-index-middle: 5;
  --z-index-high: 10;

  /* Font family */
  --LL-Circular: "LL Circular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;

  font-family: var(--LL-Circular);
  font-weight: 400;
  color: white;
  background: #291b44;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: var(--page-margin);
  position: relative;
}

@font-face {
  font-family: "LL Circular";
  src: url("/fonts/lineto-circular-black.eot");
  src: url("/fonts/lineto-circular-black.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/lineto-circular-black.woff2") format("woff2"),
    url("/fonts/lineto-circular-black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "LL Circular";
  src: url("/fonts/lineto-circular-blackitalic.eot");
  src: url("/fonts/lineto-circular-blackitalic.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/lineto-circular-blackitalic.woff2") format("woff2"),
    url("/fonts/lineto-circular-blackitalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "LL Circular";
  src: url("/fonts/lineto-circular-bold.eot");
  src: url("/fonts/lineto-circular-bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/lineto-circular-bold.woff2") format("woff2"),
    url("/fonts/lineto-circular-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "LL Circular";
  src: url("/fonts/lineto-circular-bolditalic.eot");
  src: url("/fonts/lineto-circular-bolditalic.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/lineto-circular-bolditalic.woff2") format("woff2"),
    url("/fonts/lineto-circular-bolditalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "LL Circular";
  src: url("/fonts/lineto-circular-book.eot");
  src: url("/fonts/lineto-circular-book.eot?#iefix") format("embedded-opentype"),
    url("/fonts/lineto-circular-book.woff2") format("woff2"),
    url("/fonts/lineto-circular-book.woff") format("woff");
  font-weight: 350;
  font-style: normal;
}

@font-face {
  font-family: "LL Circular";
  src: url("/fonts/lineto-circular-bookitalic.eot");
  src: url("/fonts/lineto-circular-bookitalic.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/lineto-circular-bookitalic.woff2") format("woff2"),
    url("/fonts/lineto-circular-bookitalic.woff") format("woff");
  font-weight: 350;
  font-style: italic;
}

@font-face {
  font-family: "LL Circular";
  src: url("/fonts/lineto-circular-medium.eot");
  src: url("/fonts/lineto-circular-medium.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/lineto-circular-medium.woff2") format("woff2"),
    url("/fonts/lineto-circular-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "LL Circular";
  src: url("/fonts/lineto-circular-mediumitalic.eot");
  src: url("/fonts/lineto-circular-mediumitalic.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/lineto-circular-mediumitalic.woff2") format("woff2"),
    url("/fonts/lineto-circular-mediumitalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

.hide-text {
  display: block;
  height: 0;
  text-indent: 100%;
  white-space: nowrap;
  width: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.input-optional {
  color: var(--medium-purple);
  display: inline-block;
  margin-left: 8px;
}

.TransitionWrapper-enter,
.TransitionWrapper-enter-active {
  opacity: 0;
  position: absolute;
  width: 100%;
}

.TransitionWrapper-enter-done {
  transition: var(--slow-opacity-transition);
  opacity: 1;
}

.TransitionWrapper-exit {
  transition: var(--slow-opacity-transition);
  opacity: 1;
  width: 100%;
}

.TransitionWrapper-exit-active {
  opacity: 0;
  width: 100%;
}
