.Spinner {
  border-radius: 50%;
  display: block;
  margin: 50px auto;
  width: 100px;
  height: auto;
  position: relative;
  animation: load3 1.8s infinite linear;
  transform: translateZ(0);
  transform-origin: 52px 50px;
}

.Spinner.small {
  height: 38px;
  margin: 0 14px 0 10px;
  transform-origin: 20px 19px;
  width: 38px;
}

.Spinner.medium {
  margin: 0;
  transform-origin: 42px 40px;
  width: 80px;
}

.Spinner__image {
  display: block;
  height: auto;
  width: 100%;
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
