.Card {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: var(--port-gore);
  background: var(--heliotrope);
  border-radius: 14px;
  position: relative;
  cursor: pointer;
  transition: transform 0.15s ease-in-out, var(--outline-transition);
  display: inline-block;

  --blank-base-shadow: 0 0 0 0 rgb(60, 64, 67, 0.05), 0 0 0 0 rgba(0, 0, 0, 0.3);
  --base-shadow: 0 28px 4px 0 rgb(60, 64, 67, 0.05),
    0 29px 19px 3px rgba(0, 0, 0, 0.3);
}

.Card.--carnation {
  background: var(--carnation);
  box-shadow: 0 0 0 0 var(--carnation), var(--blank-base-shadow);
}

.Card.--carnation:hover {
  box-shadow: 0 0 0 8px var(--carnation), var(--base-shadow);
}

.Card.--carnation:focus {
  box-shadow: 0 0 0 4px var(--carnation), var(--base-shadow);
  outline: none;
}

.Card.--bermuda {
  background: var(--bermuda);
  box-shadow: 0 0 0 0 var(--bermuda);
}

.Card.--bermuda:hover {
  box-shadow: 0 0 0 8px var(--bermuda), var(--base-shadow);
}

.Card.--bermuda:focus {
  box-shadow: 0 0 0 4px var(--bermuda), var(--base-shadow);
  outline: none;
}

.Card.--your_pink {
  background: var(--your_pink);
  box-shadow: 0 0 0 0 var(--your_pink);
}

.Card.--your_pink:hover {
  box-shadow: 0 0 0 8px var(--your_pink), var(--base-shadow);
}

.Card.--your_pink:focus {
  box-shadow: 0 0 0 4px var(--your_pink), var(--base-shadow);
  outline: none;
}

.Card.--tumbleweed {
  background: var(--tumbleweed);
  box-shadow: 0 0 0 0 var(--tumbleweed);
}

.Card.--tumbleweed:hover {
  box-shadow: 0 0 0 8px var(--tumbleweed), var(--base-shadow);
}

.Card.--tumbleweed:focus {
  box-shadow: 0 0 0 4px var(--tumbleweed), var(--base-shadow);
  outline: none;
}

.Card.--heliotrope {
  background: var(--heliotrope);
  box-shadow: 0 0 0 0 var(--heliotrope);
}

.Card.--heliotrope:hover {
  box-shadow: 0 0 0 8px var(--heliotrope), var(--base-shadow);
}

.Card.--heliotrope:focus {
  box-shadow: 0 0 0 4px var(--heliotrope), var(--base-shadow);
  outline: none;
}

.Card.--vivid_tangerine {
  background: var(--vivid_tangerine);
  box-shadow: 0 0 0 0 var(--vivid_tangerine);
}

.Card.--vivid_tangerine:hover {
  box-shadow: 0 0 0 8px var(--vivid_tangerine), var(--base-shadow);
}

.Card.--vivid_tangerine:focus {
  box-shadow: 0 0 0 4px var(--vivid_tangerine), var(--base-shadow);
  outline: none;
}

.Card.--ziggurat {
  background: var(--ziggurat);
  box-shadow: 0 0 0 0 var(--ziggurat);
}

.Card.--ziggurat:hover {
  box-shadow: 0 0 0 8px var(--ziggurat), var(--base-shadow);
}

.Card.--ziggurat:focus {
  box-shadow: 0 0 0 4px var(--ziggurat), var(--base-shadow);
  outline: none;
}

.Card.--sandwisp {
  background: var(--sandwisp);
  box-shadow: 0 0 0 0 var(--sandwisp);
}

.Card.--sandwisp:hover {
  box-shadow: 0 0 0 8px var(--sandwisp), var(--base-shadow);
}

.Card.--sandwisp:focus {
  box-shadow: 0 0 0 4px var(--sandwisp), var(--base-shadow);
  outline: none;
}

.Card_wrapper {
  width: 100%;
  height: 100%;
}

.Card_title {
  font-family: var(--LL-Circular);
  font-size: 18px;
  font-weight: 900;
  line-height: 28px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  margin-bottom: 0;
  margin-top: 0;
  pointer-events: none;
  right: 20px;
  text-align: left;
  z-index: 2;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  max-height: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (min-width: 480px) {
  .Card_title {
    font-size: 20px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    max-height: calc(100% - 60px);
    -webkit-line-clamp: 5;
  }
}

.Card_share {
  position: absolute;
  top: 30px;
  left: 30px;
  margin-bottom: 0;
  pointer-events: none;
  right: 30px;
  color: inherit;
  z-index: 2;
  opacity: 0.5;
}

.Card_particles {
  border-radius: 24px;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  top: -8px;
  bottom: 0;
  left: -8px;
  right: 0;
  z-index: 1;
}
