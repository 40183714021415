.Dashboard {
  width: 100%;
}

.Dashboard .dashboard-empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 360px;
  width: 100%;
}

.Dashboard .dashboard-empty .NewStoryTile {
  width: 280px;
  height: 280px;
}

.Dashboard .dashboard-empty .NewStoryTile .Button.--filled {
  left: 50%;
  top: 50%;
  transform: translateX(calc(-50% - 24px)) translateY(calc(-50% - 6px));
  padding: 24px 16px;
  width: 100%;
}

.Dashboard .dashboard-empty .NewStoryTile .bg-illustration {
  display: block;
}

.Dashboard .dashboard-get-started-headline {
  font-size: 30px;
  line-height: 32px;
  font-weight: 600;
  text-align: center;
  color: var(--rum);
}

.Dashboard .dashboard-empty h4 {
  color: var(--rum);
  font-family: var(--LL-Circular);
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  margin-top: 110px;
  text-align: center;
}

.Dashboard .dashboard-heading {
  margin: 0 auto 36px;
  padding: 30px calc((var(--column)) + (var(--gutter)));
  position: relative;
}

@media (min-width: 1024px) {
  .Dashboard .dashboard-heading {
    max-width: var(--large-content-width);
    padding: 0;
    margin-bottom: 40px;
  }
}

.Dashboard .dashboard-headline {
  font-size: 28px;
  line-height: 36px;
  margin-top: 0;
  margin-bottom: 10px;
}

.Dashboard .dashboard-subhead {
  font-size: 16px;
  color: var(--spun-pearl);
  margin: 0 0 20px;
}

@media (min-width: 768px) {
  .Dashboard .dashboard-heading .Button {
    position: absolute;
    right: 0;
    top: 30px;
    transform: translateX(calc(-1 * (var(--column) + var(--gutter))));
  }
}

@media (min-width: 1024px) {
  .Dashboard .dashboard-heading .Button {
    top: 0;
    transform: none;
  }
}

.Dashboard .dashboard-stories {
  align-items: center;
  display: grid;
  grid-row-gap: var(--gutter);
  grid-column-gap: var(--gutter);
  grid-template-columns: calc(50vw - calc(1.5) * var(--gutter)) calc(
      50vw - calc(1.5 * (var(--gutter)))
    );
  grid-auto-rows: calc(50vw - calc(1.5 * (var(--gutter))));
  justify-content: center;
}

@media (min-width: 768px) {
  .Dashboard .dashboard-stories {
    grid-template-columns: calc(42vw - calc(1.5 * (var(--gutter)))) calc(
        42vw - calc(1.5 * (var(--gutter)))
      );
    grid-auto-rows: calc(42vw - calc(1.5 * (var(--gutter))));
    grid-row-gap: var(--gutter);
    grid-column-gap: var(--gutter);
  }
}

@media (min-width: 1024px) {
  .Dashboard .dashboard-stories {
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    grid-template-columns: 221px 221px 221px;
    grid-auto-rows: 221px;
  }
}

.Dashboard .dashboard-hr {
  display: block;
  max-width: var(--large-content-width);
  margin: 48px auto 36px;
  height: 2px;
  border: none;
  border-radius: 2px;
  background-color: var(--mulled-wine);
}

.Dashboard .MyGreetingsLink {
  display: block;
  max-width: var(--large-content-width);
  margin: 0 auto;
  text-align: right;
  text-decoration: none;
}

.Dashboard .MyGreetingsLink .Headline {
  color: var(--chatelle);
  font-family: var(--LL-Circular);
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0.14px;
  margin-bottom: 20px;
}

.dashboard-story {
  position: relative;
  width: 100%;
  height: 100%;
}

.TransitionWrapper-enter .Dashboard .dashboard-story,
.TransitionWrapper-enter-active .Dashboard .dashboard-story {
  opacity: 0;
  transform: translateY(16px);
}

.TransitionWrapper-enter-done .Dashboard .dashboard-story {
  transition: var(--opacity-transition), var(--transform-transition);
  opacity: 1;
  transform: translateY(0);
}

.TransitionWrapper-exit .Dashboard .dashboard-story {
  transition: var(--opacity-transition), var(--transform-transition);
  opacity: 1;
  transform: translateY(0);
}

.TransitionWrapper-exit-active .Dashboard .dashboard-story {
  opacity: 0;
  transform: translateY(-16px);
}

.Dashboard .AddStory {
  position: relative;
  width: 220px;
  height: 280px;
}

@media (min-width: 1024px) {
  .Dashboard .AddStory {
    width: 220px;
    height: 240px;
  }
}

.Dashboard .AddStory.dashboard-story {
  width: auto;
  height: auto;
}

@media (min-width: 1024px) {
  .Dashboard .AddStory.dashboard-story {
    width: 220px;
    height: 240px;
  }
}
