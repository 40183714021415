.RadioGroup {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
}

.RadioGroup .radio-input {
  height: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  width: 0;
  z-index: -1;
}

.RadioGroup .radio-label {
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "LL Circular";
  font-size: 16px;
  font-weight: 700;
  height: 60px;
  letter-spacing: 1.37px;
  text-transform: uppercase;
  margin-right: 30px;
  padding-left: 20px;
  z-index: 2;
}

@media (min-width: 768px) {
  .RadioGroup .radio-label {
    margin: 0 40px 0 0;
    padding-left: 0;
  }
}

.RadioGroup .radio-visual {
  background-color: var(--victoria);
  border: 0;
  border-radius: 14px;
  height: 60px;
  margin-right: 10px;
  position: relative;
  transition: var(--outline-transition);
  width: 60px;
}

.RadioGroup .radio-input:focus + .radio-visual {
  box-shadow: var(--box-shadow-outline);
}

.RadioGroup .radio-check {
  color: #fff;
  height: 16px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s;
  width: 18px;
  z-index: 1;
}

.RadioGroup .radio-input:checked + .radio-visual .radio-check {
  opacity: 1;
}
