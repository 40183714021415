[data-reach-tab-list] {
  display: flex;
}

[data-reach-tab] {
  cursor: pointer;
  margin: 0;
  padding: 16px;
  font-family: var(--LL-Circular);
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 1.03px;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--aqua-spring);
  border: 4px solid var(--victoria);
  border-right-style: hidden;
  box-sizing: content-box;
  min-width: 152px;
  background-color: var(--port-gore);
  transition: var(--background-color-transition), var(--color-transition),
    var(--outline-transition);
}

[data-reach-tab]:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

[data-reach-tab]:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right-style: solid;
}

[data-reach-tab]:hover {
  background-color: var(--victoria);
}

[data-reach-tab][data-selected] {
  background-color: var(--victoria);
  border-color: var(--aqua-spring);
  border-right-style: solid;
}

[data-reach-tab][data-selected] + [data-reach-tab] {
  border-left-style: hidden;
}

[data-reach-tab]:focus {
  outline: none;
}
