.SetupInstructions {
  padding: 0 calc((var(--column)) + (var(--gutter)))
    calc((var(--column)) + (var(--gutter)));
  margin-bottom: 64px;
}

@media (min-width: 1024px) {
  .SetupInstructions {
    margin-bottom: 0;
    padding: 0;
  }
}

.SetupInstructions .setup__headline {
  color: var(--heliotrope);
  font-family: var(--LL-Circular);
  font-size: 28px;
  letter-spacing: 0.7px;
  margin: 52px auto;
  text-align: center;
}

.SetupInstructions .setup__illustration {
  max-width: 492px;
  margin: 0 auto 60px;
}

.SetupInstructions .setup__steps {
  list-style: none;
  margin: 54px auto 0;
  max-width: 790px;
  padding: 0;
  width: 100%;
}

.SetupInstructions .setup__step {
  display: flex;
  font-family: var(--LL-Circular);
  margin-bottom: 72px;
  margin-top: 50px;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .SetupInstructions .setup__step {
    flex-direction: row;
  }
}

.SetupInstructions .setup__step p {
  margin: 0 0 24px;
}

.SetupInstructions .setup__step-number {
  color: var(--alto);
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: var(--victoria);
  text-align: center;
  line-height: 58px;
  margin-bottom: 30px;
}

@media (min-width: 1024px) {
  .SetupInstructions .setup__step-number {
    margin-right: 48px;
    margin-bottom: 0;
  }
}

.SetupInstructions .setup__step-copy {
  color: var(--aqua-spring);
  font-size: 24px;
  font-weight: 500;
  line-height: 1.266666667;
  margin: 0;
  flex: 1;
}

.SetupInstructions .setup__step-link-container {
  display: flex;
  align-items: baseline;
}

.SetupInstructions .setup__step-link {
  color: var(--fog);
  font-family: var(--LL-Circular);
  font-size: 18px;
  font-weight: 350;
  line-height: 26px;
  text-decoration: none;
  border-bottom: 1px solid var(--victoria);
  padding-bottom: 6px;
  transition: color 0.15s ease, border-bottom-color 0.15s ease;
}

.SetupInstructions .setup__step-link:hover,
.SetupInstructions .setup__step-link:focus {
  color: var(--fog);
  border-bottom-color: var(--chatelle);
  outline: none;
}

.SetupInstructions .external-link-icon {
  max-width: 18px;
  margin-left: 14px;
}
