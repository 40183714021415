.Button {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: var(--LL-Circular);
  text-decoration: none;
}

.Button .Button_icon svg {
  overflow: visible;
}

.Button.--oval {
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 1.03px;
  text-transform: uppercase;
  align-items: center;
  display: inline-flex;
  padding: 10px 16px;
  border-radius: 40px;
  height: 44px;
}

@media (min-width: 768px) {
  .Button.--oval {
    padding: 12px 16px;
    height: auto;
  }
}

a.Button.--oval {
  display: inline-flex;
}

.Button.--oval-greyed-out {
  font-size: 16px;
  font-weight: 400;
  padding: 14px 20px;
}

.Button.--oval-primary .Button_label,
.Button.--oval-secondary .Button_label,
.Button.--oval-tertiary .Button_label,
.Button.--greyed-out .Button_label {
  display: block;
}

.Button.--oval .Button_icon,
.Button.--oval-tertiary .Button_icon {
  display: block;
  height: 14px;
  width: 14px;
}

.Button.--oval-greyed-out .Button_icon {
  height: 17px;
  width: 17px;
}

.Button.--oval.--icon-left .Button_icon {
  margin-right: 8px;
}

.Button.--oval.--icon-right .Button_icon {
  margin-left: 8px;
  order: 1;
}

.Button.--oval-greyed-out.--icon-left .Button_icon {
  margin-right: 12px;
}

.Button.--oval-primary.--unfilled {
  border: 4px solid var(--aqua-spring);
  background-color: var(--port-gore);
  color: var(--aqua-spring);
  transition: var(--background-color-transition), var(--outline-transition),
    var(--border-color-transition), var(--color-transition);
}

.Button.--oval-primary.--unfilled:hover {
  background-color: var(--victoria);
  box-shadow: 0 0 0 4px var(--victoria);
  border-color: var(--victoria);
}

.Button.--oval-primary.--unfilled:focus,
.Button.--oval-primary.--unfilled:active {
  background-color: #fff;
  color: var(--port-gore);
  outline: none;
}

.Button.--oval-primary.--filled {
  border: none;
  background-color: #fff;
  border-color: #fff;
  color: var(--port-gore);
  transition: var(--outline-transition), var(--color-transition);
}

.Button.--oval-primary.--filled:hover,
.Button.--oval-primary.--filled:focus,
.Button.--oval-primary.--filled:active {
  box-shadow: var(--box-shadow-outline);
  color: var(--heliotrope);
  outline: none;
}

.Button.--oval-secondary {
  color: var(--aqua-spring);
  border: 4px solid var(--victoria);
  background-color: var(--port-gore);
  transition: var(--background-color-transition), var(--color-transition),
    var(--border-color-transition), var(--outline-transition);
}

.Button.--oval-secondary:hover {
  background-color: var(--victoria);
  box-shadow: 0 0 0 4px var(--victoria);
}

.Button.--oval-secondary:focus,
.Button.--oval-secondary:active {
  background-color: var(--aqua-spring);
  border-color: var(--aqua-spring);
  color: var(--port-gore);
  outline: none;
}

.Button.--oval-secondary:active {
  outline: none;
  border-color: #fff;
  background-color: #fff;
  color: var(--port-gore);
}

/* Filled Round button */
.Button.--oval-filled-round {
  color: #fff;
  border: 4px solid var(--victoria);
  background-color: var(--victoria);
  padding: 10px 20px;
  transition: var(--background-color-transition), var(--color-transition),
    var(--border-color-transition), var(--outline-transition);
}

.Button.--oval-filled-round:hover {
  background-color: var(--port-gore);
}

.Button.--oval-filled-round:focus,
.Button.--oval-filled-round:active {
  background-color: var(--aqua-spring);
  border-color: var(--aqua-spring);
  color: var(--port-gore);
  outline: none;
}

.Button.--oval-filled-round:active {
  outline: none;
  border-color: #fff;
  background-color: #fff;
  color: var(--port-gore);
}

.Button.--oval:disabled,
.Button.--oval.--disabled {
  background: none !important;
  border: 4px solid var(--aqua-spring) !important;
  color: var(--aqua-spring) !important;
  cursor: auto;
  pointer-events: none;
  opacity: 0.3;
}

.Button.--oval-tertiary,
a.Button.--oval-tertiary {
  padding: 8px 12px;
  border-radius: 20px;
  color: var(--aqua-spring);
  display: inline-flex;
  border: 3px solid var(--victoria);
  background-color: var(--port-gore);
  transition: var(--background-color-transition), var(--color-transition),
    var(--border-color-transition), var(--outline-transition);
}

.Button.--oval-tertiary:hover {
  background-color: var(--victoria);
  box-shadow: 0 0 0 3px var(--victoria);
}

.Button.--oval-tertiary:focus,
.Button.--oval-tertiary:active {
  background-color: #fff;
  border-color: #fff;
  color: var(--port-gore);
  outline: none;
}

.Button.--oval-tertiary:active {
  outline: none;
  border-color: #fff;
  background-color: #fff;
  color: var(--port-gore);
}

.Button.--oval-tertiary:disabled,
.Button.--oval-tertiary.--disabled {
  background: none !important;
  border: 3px solid var(--aqua-spring) !important;
  color: var(--aqua-spring) !important;
  cursor: auto;
  pointer-events: none;
  opacity: 0.3;
}

.Button.--oval-greyed-out {
  border: 3px solid var(--spun-pearl);
  color: var(--spun-pearl);
  transition: var(--background-color-transition), var(--outline-transition),
    var(--border-color-transition), var(--color-transition);
}

.Button.--circle {
  color: var(--aqua-spring);
  border-radius: 50%;
  border: 0;
  transition: var(--background-color-transition), var(--color-transition),
    var(--outline-transition);
}

.Button.--circle:focus,
.Button.--circle:active {
  box-shadow: var(--box-shadow-outline);
  color: #fff;
  outline: none;
}

.Button.--circle.--unfilled {
  background: transparent;
}

.Button.--circle.--unfilled:hover {
  box-shadow: var(--box-shadow-outline);
  color: #fff;
}

.Button.--circle .Button_icon {
  display: block;
  margin: auto;
}

.Button.--circle-small {
  width: 38px;
  height: 38px;
}

.Button.--circle-small:hover,
.Button.--circle-small:focus {
  box-shadow: var(--box-shadow-outline-small);
  color: #fff;
  outline: none;
}

.Button.--circle-medium {
  width: 64px;
  height: 64px;
}

.Button.--circle-large {
  width: 80px;
  height: 80px;
}

.Button.--circle-small .Button_icon {
  width: 22px;
  height: 22px;
}

.Button.--circle-small.--circle-edit .Button_icon {
  width: 18px;
  height: 18px;
}

.Button.--circle-medium .Button_icon {
  width: 32px;
  height: 32px;
}

.Button.--circle-large .Button_icon {
  width: 42px;
  height: 42px;
}

.Button.--circle:disabled,
.Button.--circle.--disabled {
  border: 0 !important;
  box-shadow: none !important;
  opacity: 0.4;
  cursor: auto;
  pointer-events: none;
}

.Button.--circle-close {
  color: var(--aluminium);
}

.Button.--circle-close:hover,
.Button.--circle-close:active {
  box-shadow: none;
}

.Button.--circle-small.--circle-close:hover {
  box-shadow: none;
}

.Button.--circle-delete {
  background: var(--bossanova);
}

.Button.--circle-delete:hover {
  background: var(--bossanova);
  box-shadow: 0 0 0 4px var(--bossanova);
}

.Button.--circle-small.--circle-delete:hover {
  box-shadow: 0 0 0 3px var(--bossanova);
}

.Button.--circle-edit {
  color: var(--heliotrope);
}

.Button.--circle-play {
  background: var(--heliotrope);
}

.Button.--circle-play:hover {
  background: var(--heliotrope);
  box-shadow: 0 0 0 4px var(--heliotrope);
}

.Button.--circle-small.--circle-play:hover {
  box-shadow: 0 0 0 3px var(--heliotrope);
}

.Button.--circle-record {
  background: var(--carnation);
}

.Button.--circle-record:hover {
  background: var(--carnation);
  box-shadow: 0 0 0 4px var(--carnation);
}

.Button.--circle-small.--circle-record:hover {
  box-shadow: 0 0 0 3px var(--carnation);
}

.Button.--circle-up {
  transform: rotateZ(90deg);
}

.Button_file-wrapper {
  display: inline-block;
  position: relative;
}

.Button.--file {
  position: relative;
  overflow: hidden;
}

.Button_file:focus ~ .Button,
.Button_file:active ~ .Button {
  background-color: #fff;
  color: var(--port-gore);
  outline: none;
}

.button-minimal {
  background: none;
  border: 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.45px;
  font-weight: 500;
  color: #fff;
  font-family: var(--LL-Circular);
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: transparent;
  transition: color 0.15s ease, text-decoration-color 0.15s ease;
}

.button-minimal:hover {
  color: var(--fog);
  text-decoration-color: var(--chatelle);
}

.button-minimal.--small {
  font-size: 12px;
  padding: 0;
}

.Button.--circle-play.--circle-small.--unfilled,
.Button.--circle-delete.--circle-small.--unfilled {
  box-shadow: var(--box-shadow-outline-small-dark);
}

.Button.--circle-play.--circle-small.--unfilled:hover,
.Button.--circle-delete.--circle-small.--unfilled:hover {
  background: var(--deluge);
}

.Button.--circle-play.--circle-small.--unfilled:active,
.Button.--circle-play.--circle-small.--unfilled:focus,
.Button.--circle-play.--circle-delete.--unfilled:active,
.Button.--circle-play.--circle-delete.--unfilled:focus {
  background: #fff;
  color: var(--port-gore);
  box-shadow: 0 0 0 3px #fff;
}

@media (min-width: 768px) {
  .Button.--circle-play.--circle-small.--unfilled:hover,
  .Button.--circle-delete.--circle-small.--unfilled:hover,
  .Button.--circle-play.--circle-small.--unfilled:active,
  .Button.--circle-play.--circle-small.--unfilled:focus,
  .Button.--circle-play.--circle-delete.--unfilled:active,
  .Button.--circle-play.--circle-delete.--unfilled:focus {
    background: transparent;
    box-shadow: 0 0 0 3px var(--heliotrope);
    color: #fff;
  }
}

.Button.--circle-delete-editor {
  background: transparent;
  color: var(--carnation);
  border: solid 2px;
  width: 18px;
  height: 18px;
  padding: 0;
}
