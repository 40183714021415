.CreateChapter {
  padding: 32px 0 56px 0;
  min-height: 100%;
  width: 100%;
}

.CreateChapter_footer .center-content {
  width: 0;
}
.CreateChapter_footer .right-content {
  width: auto;
}
