.Modal__Overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(41, 27, 68, 0.75);
  z-index: 10;
  display: flex;
}

.Modal__Container {
  position: absolute;
  outline: none;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  overflow: visible;
}

.Modal__Container.--account,
.Modal__Container.--edit-chapter-title {
  position: static;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-right: 0;
  transform: translate(0%, 0%);
  display: flex;
  flex: 1;
}

/* Desktop+ */
@media (min-width: 1024px) {
  .Modal__Container.--account {
    position: absolute;
    top: 96px;
    right: 24px;
    left: auto;
    bottom: auto;
    display: block;
  }
}

.Modal {
  width: calc(100vw - var(--page-margin-small));
  color: white;
  background: #291b44;
  padding: var(--page-margin-small);
  font-size: 16px;
  letter-spacing: 0.45px;
  line-height: 24px;
  text-align: center;
  border: 3px solid white;
  border-radius: 14px;
  font-family: var(--LL-Circular);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

@media (min-width: 768px) {
  .Modal {
    width: 600px;
    padding: 64px;
  }
}

.Modal.--animate-in {
  opacity: 1;
  transform: translateY(0);
}

.Modal.--animate-out {
  opacity: 0;
  transform: translateY(20px);
}

.Modal.--account,
.Modal.--edit-chapter-title {
  width: auto;
  border-style: none;
  padding: var(--page-margin);
  font-size: 28px;
  letter-spacing: 0.5px;
  line-height: 38px;
  text-align: left;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.Modal.--edit-chapter-title .ChapterTitleInfo {
  min-height: initial;
  top: -56px;
  position: relative;
}

.Modal.--edit-chapter-title
  .ChapterTitleInfo
  .create_chapter_name_input_wrapper {
  margin-bottom: 32px;
}

/* Desktop */
@media (min-width: 1024px) {
  .Modal.--account {
    display: block;
    width: 300px;
    padding: 24px;
    border-style: solid;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.2px;
    line-height: 18px;
  }
}

@media (min-width: 1024px) and (max-height: 680px) {
  .Modal.--account {
    -webkit-overflow-scrolling: touch;
    height: calc(100vh - 120px);
    overflow-y: auto;
  }
}

.Modal_accountContent {
  max-width: 382px;
  flex: 1;
  margin: auto;
  padding: 40px 0;
}

/* Tablet+ */
@media (min-width: 768px) {
  .Modal_accountContent {
    max-width: 618px;
  }
}

.Modal_close {
  position: absolute;
  right: var(--page-margin);
  top: var(--page-margin);
}

/* Desktop+ */
@media (min-width: 1024px) {
  .Modal_close {
    display: none;
  }
}

.Modal_accountLinksList {
  list-style: none;
  margin: 0 0 60px;
  padding: 0;
}

/* Desktop */
@media (min-width: 1024px) {
  .Modal_accountLinksList {
    margin-bottom: 32px;
  }
}

.Modal_email {
  font-size: 14px;
  font-weight: bold;
  line-height: 24;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Modal_accountLinks {
  border-bottom: 1px solid rgba(216, 216, 216, 0.26);
  padding-bottom: 17px;
  margin-bottom: 17px;
}

.Modal_accountLinksList {
  list-style: none;
  margin: 0 0 32px;
  padding: 0;
}

.Modal_faqLink {
  align-items: center;
  color: white;
  display: flex;
  text-decoration: none;
}

.Modal_email {
  line-height: 1.714285714;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Modal_faqLink:hover {
  text-decoration: underline;
}

.Modal_faqIcon {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 8px;
}

.Modal_headline {
  font-size: 48px;
  line-height: 1.25;
}

.Modal_text {
  font-size: 16px;
  margin: 0;
}

.Modal_text ~ .Button {
  margin-top: 32px;
}

.Modal .Button.--oval + .Button.--oval {
  margin-left: 24px;
}

.Modal.--processing-indicator p {
  font-size: 24px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
}

.Modal.--processing-indicator p.--active {
  opacity: 1;
}

.Modal__processing-indicators {
  position: relative;
  width: 100%;
  height: 30px;
  margin: 40px 0 6px 0;
}

.Modal__processing-loader {
  height: auto;
  width: 150px;
}

.Modal .SpeechIllustration,
.Modal .FolderIllustration {
  margin: 0 auto 20px;
}
